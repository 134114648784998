<template>
  <page-view class="metaverse-detail" :up-class="'metaverse-detail'">
    <div class="detail-content">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          ><span
            class="route-item"
            @click="$router.replace({ name: 'Metaverse' })"
            ref="detailRef"
            >产品</span
          >
        </el-breadcrumb-item>
        <el-breadcrumb-item><span>详情</span></el-breadcrumb-item>
      </el-breadcrumb>
      <div class="detail-title">
        <div class="title-name">{{ detail.title }}</div>
        <div class="title-time">
          发布时间：{{ detail.updateDate?.split(" ")[0] }}
        </div>
      </div>
      <div class="detail" v-html="detail.content"></div>
      <div class="next-box">
        <div class="prev">
          <div class="btn" @click="prev(detail.sort)">上一篇</div>
          <div class="prev-name" @click="prev(detail.sort)">{{ prevName }}</div>
        </div>
        <div class="next">
          <div class="btn" @click="next(detail.sort)">下一篇</div>
          <div class="prev-name" @click="next(detail.sort)">{{ nextName }}</div>
        </div>
      </div>
    </div>
    <footer-com></footer-com>
  </page-view>
</template>
<script>
import axios from "axios";
export default {
  name: "MetaverseDetail",
  data() {
    return {
      detail: {},
      articleListAll: [],
      sort: 0,
      flag: true,
    };
  },
  computed: {
    prevName() {
      if (this.articleListAll.length <= 0) {
        return;
      }
      return this.sort <= 1
        ? "没有了"
        : this.articleListAll.find((item) => item.sort == this.sort - 1).title;
    },
    nextName() {
      if (this.articleListAll.length <= 0) {
        return;
      }
      return this.sort >= this.articleListAll.length
        ? "没有了"
        : this.articleListAll.find((item) => item.sort == this.sort + 1).title;
    },
  },
  created() {
    this.getArticleDetail(this.$route.params.id);
    this.getArticleListAll();
  },
  methods: {
    getArticleListAll() {
      axios
        .get(
          `https://zzx.zhenonline.com//mobile/article/list?type=16&categoryId=334&index=0`
        )
        .then((res) => {
          this.articleListAll = res.data.data.list;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArticleDetail(id) {
      axios
        .get(
          `https://zzx.zhenonline.com//mobile/article/detail?id=${id}&type=16`
        )
        .then((res) => {
          this.detail = res.data.data.article;
          this.sort = this.detail.sort;
          //修改网页标题
          document.title = `百川商展-元宇宙-${this.detail.title}`;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    prev(sort) {
      if (sort <= 1) {
        return false;
      } else {
        let id = this.articleListAll.find((item) => item.sort === sort - 1).id;
        this.getArticleDetail(id);
        this.$refs.detailRef.scrollIntoView({
          block: "center",
        });
      }
    },
    next(sort) {
      if (sort >= this.articleListAll.length) {
        return false;
      } else {
        let id = this.articleListAll.find((item) => item.sort === sort + 1).id;
        this.getArticleDetail(id);
        this.$refs.detailRef.scrollIntoView({
          block: "center",
        });
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.metaverse-detail {
  .detail-content {
    width: 70%;
    margin: 0 auto;
    padding: 50px 0;
    @media screen and (max-width: 1200px) {
      width: 90%;
    }

    :deep(.el-breadcrumb) {
      font-size: 18px;

      .route-item {
        &:hover {
          cursor: pointer;
          color: $primaryColor;
        }
      }
    }

    .detail-title {
      text-align: center;
      margin: 30px 0;

      .title-name {
        font-size: 38px;
        font-weight: bold;
        margin-bottom: 10px;
        @media screen and (max-width: 1200px) {
          font-size: 0.54rem;
        }
      }

      .title-time {
        font-size: 14px;
      }
    }

    :deep(.detail) {
      font-size: 24px;
      line-height: 2;
      min-height: 400px;

      img {
        width: 80%;
        text-align: center;
        @media screen and (max-width: 1200px) {
          width: 100%;
        }
      }
    }

    .next-box {
      font-size: 15px;
      @include flexbox(column, flex-start, flex-start);

      .prev {
        @include flexbox(row, flex-start, center);
        margin-top: 20px;
        cursor: pointer;

        .btn {
          background-color: #0732da;
          color: #fff;
          margin-right: 10px;
          padding: 7px 30px;
          border-radius: 6px;
          clip-path: polygon(30px 0, 100% 0, 100% 100%, 30px 100%, 0 50%);
        }
      }

      .next {
        @extend .prev;

        .btn {
          clip-path: polygon(0px 0, 76% 0, 100% 50%, 76% 100%, 0 100%);
        }
      }
    }
  }
}
</style>